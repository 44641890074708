import { useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Logout(props) {
    const navigate = useNavigate();
    const users = JSON.parse(localStorage.getItem("user-info"));
    useEffect(() => {
        clearCacheUser();
        //navigate("/login");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function clearCacheUser() {
        const result = await fetch(global.config.url.api + "login/clearCache", {
            headers: {
                token: users.token,
                "user-id": users.id,
            },
        });
        const results = await result.json();
        if (results.code !== "") {
            localStorage.removeItem("user-info");
            //toast.success(results.msg);
            navigate("/login");
        }
    }
}
export default memo(Logout);
