import { memo } from "react";
import { Link } from "react-router-dom";
import SystemManage from "./components/Sidebar/SystemManage";
function Sidebar(props) {
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* Brand Logo */}
            <Link to="/" className="brand-link">
                <img src="dist/img/logo.png" alt="Admin Logo" className="brand-image img-circle elevation-3" style={{ opacity: ".8" }} />
                <span className="brand-text font-weight-light">PHẦN MỀM QUẢN LÝ DỮ LIỆU TỔNG HỢP</span>
            </Link>
            {/* Sidebar */}
            <div className="sidebar">
                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <SystemManage />
                </nav>
                {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
        </aside>
    );
}

export default memo(Sidebar);
