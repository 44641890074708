import React, { useState, useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { encodeScript } from "../EncryptData";
import "./css/default.css";
import image_logo from "./img/logo_ag.png";

function Login() {
    const [flag] = useState(encodeScript("webApp"));
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [timeCurrent, setTimeCurrent] = useState("");

    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem("user-info")) {
            navigate("/");
        }
        getTimeCurrentServer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    ////////////////
    async function getTimeCurrentServer() {
        let flags = { flag };
        let result = await fetch(global.config.url.api + "login/getTime", {
            method: "POST",
            body: JSON.stringify(flags),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        result = await result.json();
        setTimeCurrent(result.time);
    }
    async function login(event) {
        //Prevent page reload
        event.preventDefault();
        const d = encodeScript(email + "<+>" + password + "<+>" + timeCurrent);
        let item = { d };
        let result = await fetch(global.config.url.api + "login", {
            method: "POST",
            body: JSON.stringify(item),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        result = await result.json();
        if (result.code === 200) {
            localStorage.setItem("user-info", JSON.stringify(result.data));
            navigate("/");
        }
        if (result.code === 201) {
            toast.error(result.msg);
        }
    }
    //////////log-out////////////
    return (
        <div className="wrappers fadeInDown">
            <div id="formContent">
                <form onSubmit={(e) => login(e)}>
                    <div className="fadeIn first mb-2 text-primary font-weight-bold h5 mb-4">
                        {/* <i className="fas fa-sign-in-alt mr-3"></i>  */}
                        PHẦN MỀM<br/> QUẢN LÝ DỮ LIỆU TỔNG HỢP CẤP XÃ 
                    </div>
                    <input type="text" id="login" className="fadeIn second login_input mb-3" defaultValue={email} onChange={(e) => setEmail(e.target.value)} placeholder="Nhập tên đăng nhập" />
                    <br />
                    <input type="password" id="password" className="fadeIn third login_input mb-4" onChange={(e) => setPassword(e.target.value)} placeholder="Nhập mật khẩu" />
                    <br />
                    <button onClick={(e) => login(e)} className="btn fadeIn fourth login_submit btn-primary">
                        <i className="fas fa-sign-in-alt mr-3"></i>
                        <b>Đăng nhập</b>
                    </button>
                </form>
            </div>
            <div class="sidebar">
                <div class="logo">
                    <img src={image_logo} alt="" />
                </div>
                <div class="comp-name">
                    <div class="name">
                        Trung tâm Công nghệ
                        <br />
                        thông tin và truyền thông
                    </div>
                    <div class="local"> </div>
                </div>
                <div class="info">
                    <p>
                        <strong>Thông tin liên hệ:</strong>
                    </p>
                    <p>
                        <i className="fas fa-home"></i> - Số 82, Tôn Đức Thắng, phường Mỹ Bình, thành phố Long Xuyên, tỉnh An Giang
                    </p>
                    <p>
                        <i className="fas fa-phone"></i> - (0296) 3856.188
                    </p>
                    <p class="webs">
                        <a target="_blank" href="https://ictangiang.vn/">
                            <i className="fas fa-globe"></i> - ictangiang.vn
                        </a>
                    </p>
                    <p>
                        <i className="fas fa-envelope"></i> - cict@angiang.gov.vn{" "}
                    </p>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}
export default memo(Login);
