import { memo } from "react";
import LeftNavbar from "./components/Header/LeftNavbar";
import Messages from "./components/Header/Messages";
import Notifications from "./components/Header/Notifications";
//import Search from './components/Header/Search';
import UserLogin from "./components/Header/UserLogin";

function Header(props) {
  const users = JSON.parse(localStorage.getItem("user-info"));
  return (
    <nav className="main-header navbar navbar-expand navbar-dark">
      {/* Left navbar links */}
      <LeftNavbar nameOrganizeManager={users !== null ? users.nameOrganizeRole : ""}></LeftNavbar>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        {/* Navbar Search */}
        {/* <Search></Search> */}
        {/* Messages Dropdown Menu */}
        {/* <Messages></Messages> */}
        {/* Notifications Dropdown Menu */}
        {/* <Notifications></Notifications> */}
        {/* UserLogin Dropdown Menu */}
        <UserLogin users={users} avatar={props.avatar}></UserLogin>
        <li className="nav-item">
          <a className="nav-link" data-widget="fullscreen" href="#" role="button">
            <i className="fas fa-expand-arrows-alt" />
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default memo(Header);
